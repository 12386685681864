import React from 'react'
import HeroGradient from 'components/hero-image/hero-image-gradient'
import Floater from 'components/floating-grid'
import Copy from 'components/category/copy'
import Content from 'components/category/content'
import { Helmet } from 'react-helmet'
import Promos from 'components/promos'
import Layout from 'components/layout'
import { graphql, Link } from 'gatsby'

const items = [
  {
    link: '/lawn-garden/lawn-tractors/',
    imageId: 'lawnTractorsImage',
    name: 'Riding Lawn Mowers',
    alt: 'John Deere Riding Lawn Mowers',
  },
  {
    link: '/lawn-garden/residential-zero-turns/',
    imageId: 'residentialZtraksImage',
    name: 'Residential Zero-Turn Mowers',
    alt: 'John Deere Residential Zero-Turns',
  },
  {
    link: '/lawn-garden/commercial-mowers/',
    imageId: 'commercialZtraksImage',
    name: 'Commercial Mowers',
    alt: 'John Deere Commercial Mowers',
  },
  {
    link: '/lawn-garden/compact-tractors/',
    imageId: 'compactTractorsImage',
    name: 'Compact Tractors',
    alt: 'John Deere Compact Tractors',
  },
  {
    link: '/lawn-garden/gators/',
    imageId: 'gatorsImage',
    name: 'Gators',
    alt: 'John Deere Gators',
  },
]

const breadcrumbsSchema = JSON.stringify({
  '@context': 'http://schema.org',
  '@type': 'BreadcrumbList',
  'itemListElement': [
    {
      '@type': 'ListItem',
      'position': 1,
      'name': 'Hutson Inc',
      'item': 'https://www.hutsoninc.com/',
    },
    {
      '@type': 'ListItem',
      'position': 2,
      'name': 'Lawn & Garden Equipment',
      'item': 'https://www.hutsoninc.com/lawn-garden/',
    },
  ],
})

const LawnGardenPage = ({ data }) => {
  const { allHutsonPromotion, heroImage } = data
  const promos = []
  if (allHutsonPromotion) {
    allHutsonPromotion.nodes.forEach(node => promos.push(node))
  }
  const gridItems = items.map(item => {
    item.image = data[item.imageId]
    return item
  })
  return (
    <Layout>
      <Helmet>
        <title>John Deere Lawn &amp; Garden Equipment | Hutson Inc</title>
        <meta
          name='description'
          content='Shop our complete line-up of John Deere residential equipment including lawn mowers, compact utility tractors, gators, attachments, and more.'
        />
        <meta
          name='keywords'
          content='lawnmowers, gators, compact tractors, attachments, John Deere, homeowner, lawn care'
        />
        <script type='application/ld+json'>{breadcrumbsSchema}</script>
      </Helmet>

      <HeroGradient image={heroImage} heading='Lawn &amp; Garden' />

      <Content>
        <Floater items={gridItems} />

        <Copy header='John Deere Lawn and Garden Equipment'>
          <p>
            Hutson offers a complete line of John Deere residential products including{' '}
            <Link to='/lawn-garden/mowers/'>lawnmowers</Link>,{' '}
            <Link to='/lawn-garden/gators/'>gators</Link>,{' '}
            <Link to='/lawn-garden/compact-tractors/'>compact tractors</Link>,{' '}
            <Link to='/implements/'>implements</Link> and more. We have the equipment to keep your
            property looking its best while enjoying our many different financing options. Hutson
            also offers{' '}
            <Link to='/lawn-garden/compact-tractors/'>4 different series of compact tractors</Link>{' '}
            ranging from 23 hp to 66 hp all with a variety of attachments and implements. Our line
            of <Link to='/lawn-garden/gators/'>Gator UTV’s</Link> is sure to bring work and
            recreation together with color, seating and horsepower options. Be sure to check out our
            great financing options through John Deere Financial for a quick and seamless
            transaction!
          </p>
        </Copy>

        <Promos data={promos} type='Lawn &amp; Garden Equipment' />
      </Content>
    </Layout>
  )
}

export const pageQuery = graphql`
  query lawnAndGardenCategoryQuery($category: String = "lawn-garden") {
    allHutsonPromotion(
      filter: {
        categoryList: { elemMatch: { category: { eq: $category }, type: { eq: "john-deere" } } }
      }
      sort: { fields: [endDate], order: DESC }
      limit: 3
    ) {
      nodes {
        ...HutsonPromo
      }
    }
    heroImage: file(relativePath: { eq: "lawn-and-garden/lawn-and-garden-bg.jpg" }) {
      ...FullWidthImage
    }
    lawnTractorsImage: file(relativePath: { eq: "lawn-and-garden/x700-series.jpg" }) {
      ...FloatingGridImage
    }
    residentialZtraksImage: file(relativePath: { eq: "lawn-and-garden/z300-series.jpg" }) {
      ...FloatingGridImage
    }
    commercialZtraksImage: file(relativePath: { eq: "lawn-and-garden/z900-series.jpg" }) {
      ...FloatingGridImage
    }
    compactTractorsImage: file(relativePath: { eq: "lawn-and-garden/4-family.jpg" }) {
      ...FloatingGridImage
    }
    gatorsImage: file(relativePath: { eq: "lawn-and-garden/mid-size-crossover-gators.jpg" }) {
      ...FloatingGridImage
    }
  }
`

export default LawnGardenPage
